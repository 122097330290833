import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { FaInstagram, FaTwitter, FaGithub } from 'react-icons/fa'
import { Layout, WrapperTwo, Title, SEO, Header } from '../components'
import website from '../../config/website'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.white};
  padding-top: 1rem;
  padding-bottom: 2rem;
  color: ${props => props.theme.colors.primary};
  div {
    padding-top: 3em;
  }
  h1 {
    color: ${props => props.theme.colors.primary};
  }
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.greyDarker};
  font-size: 1.25rem;
  padding-top: 3rem;
`
const StyledWrapperTwo = styled(WrapperTwo)`
  min-height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
  p > a {
    font-style: normal;
    font-weight: normal;
    color: ${props => props.theme.colors.orangeLighter};
  }
  > div {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
  }
  div a {
    color: ${props => props.theme.colors.greyDarker};
  }
  div a:hover {
    opacity: 0.6;
  }
  p > a:hover {
    color: ${props => props.theme.colors.primary};
    opacity: 1;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    min-height: calc(100vh - 290px);
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
  }
`
const Form = styled.form`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.greyDarker};
  font-size: 1.25rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-top: 1rem;
  }
  textarea:focus,
  button:focus,
  input:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }

  textarea {
    border: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  button {
    border: 1px solid rgba(0, 0, 0, 0.33);
    width: 200px;
    align-self: center;
    border-radius: 10px;
    padding: 0.2rem;
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    > div {
      width: 100%;
    }
  }
`
const Category = ({}) => (
  <Layout>
    <SEO title="Contact" pathname="contact" />
    <Header />
    <Hero>
      <WrapperTwo>
        <Headline>Let's get in touch:</Headline>
      </WrapperTwo>
    </Hero>
    <StyledWrapperTwo>
      <Title style={{ color: 'black', paddingLeft: 0 }}>
        If you're interested in working with me just hit me up
        <a href="mailto:andreas@rippl.at"> andreas@rippl.at</a> or just send a message:
        <Form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <label>Your Email:</label>
            <input type="email" name="email" />
          </div>
          <div>
            <label>Message:</label>
            <textarea name="message" />
          </div>
          <div>
            <button type="submit">Send</button>
          </div>
        </Form>
      </Title>
      <div>
        <a href="https://instagram.com/andirippl">
          <FaInstagram />
        </a>
        <a href="https://twitter.com/ripplandreas">
          <FaTwitter />
        </a>
        <a href="https://github.com/andreasrippl">
          <FaGithub />
        </a>
      </div>
    </StyledWrapperTwo>
  </Layout>
)

export default Category
